











































































































import CopyButton from '@/components/CopyButton.vue'
import ReleaseDistributionDetails from '@/components/ReleaseDistributionDetails.vue'
import { Vue, Component } from 'vue-property-decorator'
import { ReleaseDTO } from '@/api-client'
import { AxiosResponse } from 'axios'
import ReleaseStatus from '@/types/ReleaseStatus'
import { CONTACT_EMAIL, PRODUCT_NAME } from '@/modules/config'

@Component({
  components: {
    ReleaseDistributionDetails,
    CopyButton,
  },
  head: {
    title() {
      return {
        inner: 'Congratulations!',
      }
    },
  },
})
export default class CreateRelease extends Vue {
  PRODUCT_NAME = PRODUCT_NAME
  CONTACT_EMAIL = CONTACT_EMAIL

  loading = true
  release: ReleaseDTO | null = null
  invoiceOnly = false

  async beforeMount() {
    await this.$api.release
      .v1ReleasesReleaseIdGet(this.$route.params.id)
      .then(res => {
        if (res.status === 200) {
          this.release = ((res as unknown) as AxiosResponse<ReleaseDTO>).data
          if (
            this.$route.query.invoiceOnly &&
            this.$route.query.invoiceOnly == 'true'
          ) {
            this.invoiceOnly = true
          }
          if (this.release.status !== ReleaseStatus.Published)
            this.$router.push(`/releases/${this.$route.params.id}`)
        } else {
          this.$router.push({
            path: '/error',
            params: {
              statusCode: res.status.toString(),
              message: `Release ${this.$route.params.id} does not exist`,
            },
          })
        }
      })
      .catch(err => {
        this.$router.push({
          path: '/error',
          params: { statusCode: '404', message: err.message },
        })
      })
  }
}
