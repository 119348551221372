import { ReleaseStatus } from '@/api-client'

// Unfortunately Open API spec does not allow us to document enums
// So we have this file that needs to be kept up to date with API

// What the statuses mean:
// draft -> Release has not been published
// published -> Release paid for in stripe and applications are open
// allocated -> Allocation has run and buyers have been emailed their allocated lot. Aka today's date > allocation date
// complete -> Buyers have had a chance to accept / reject application. Aka today's date > allocation date + 2 hours
// cancelled -> seller manually cancelled the release and buyers will see 404 page if they try to access

const ReleaseStatusCustom: Record<
  string | ReleaseStatus,
  ReleaseStatus | string
> = {
  Cancelled: 0,
  Draft: 1,
  Published: 2,
  Allocated: 3,
  Complete: 4,
  Deleted: 5,
  OnDemandPurchasing: 6,
  0: 'Cancelled',
  1: 'Draft',
  2: 'Published',
  3: 'Allocated',
  4: 'Complete',
  5: 'Deleted',
  6: 'Buy Now',
}

export const NonPreviewStatuses = [
  ReleaseStatusCustom.OnDemandPurchasing,
  ReleaseStatusCustom.Published,
]

export default ReleaseStatusCustom
