









































import CopyButton from './CopyButton.vue'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { PRODUCT_NAME } from '@/modules/config'

@Component({
  components: {
    CopyButton,
  },
})
export default class ReleaseDistributionDetails extends Vue {
  @Prop({
    required: true,
    type: String,
  })
  code!: string

  @Prop({
    type: Boolean,
  })
  showButton!: string

  PRODUCT_NAME = PRODUCT_NAME

  get releaseUrlPretty() {
    return `${window.location.hostname}/r/${this.code}`
  }

  get releaseUrl() {
    return `${window.location.protocol}//${window.location.host}/r/${this.code}`
  }

  get releaseUrlRelative() {
    return `/r/${this.code}`
  }

  get isCustomCodeSlot() {
    return !!this.$slots['custom-code'] || !!this.$scopedSlots['custom-code']
  }

  get isCustomUrlSlot() {
    return !!this.$slots['custom-url'] || !!this.$scopedSlots['custom-url']
  }
}
