












import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class CopyButton extends Vue {
  @Prop({ default: 'Copy', type: String }) text!: string
  @Prop({ required: true, type: String }) value!: string
}
